<template lang="pug">
footer.footer
	b-container
		b-row
			b-col(cols="12" md="3")
				img(src="@/assets/img/okcarpremium.png", :alt="$appName").footer-img
				p.footer-copyright Copyright © 2020 {{ $appName }} © de sus respectivos propietarios
			b-col(cols="12" md="3")
				h5  Carrocerías
				b-row
					b-col(cols="6")
						ul
							li(v-for="bodywork in bodyworks.slice(1,6)")
								router-link(:to="`/filters?bodywork=${bodywork.slug}`") {{ bodywork.name }}
					b-col(cols="6")
						ul
							li(v-for="bodywork in bodyworks.slice(6,10)")
								router-link(:to="`/filters?bodywork=${bodywork.slug}`") {{ bodywork.name }}
			b-col(cols="12" md="3")
				h5 Contacto
				ul
					li
						b-link(href="tel:+56 9 6841 5352").d-block +56 9 6841 5352
					li
						b-link(href="mailto:pablo@okcarpremium.cl").d-block pablo@okcarpremium.cl
			b-col(cols="12" md="3")
				h5 &nbsp;
		SocialChat(icon :attendants="attendants")
			p(slot="header") Haz clic en uno de nuestros asistentes para chatear por WhatsApp.
			template(v-slot:button)
				img( src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg" alt="icon whatsapp" aria-hidden="true")
			small(slot="footer") Horario de apertura: De 9:00 a 18:00 horas
</template>
<script>
import {mapActions, mapState} from "vuex";
import { SocialChat } from 'vue-social-chat'

export default {
	name: 'Footer',
	data: () => ({
    attendants: [
      {
        app: 'whatsapp',
        label: 'Asesor de venta',
        name: 'M.Lesoin',
        number: '56968415352',
        avatar: {
          src: 'https://okcarpremium.cl/img/okcarpremium.feecb967.png',
          alt: 'M.Lesoin'
        }
      },
      // ...
    ]
  }),
	computed: {
	...mapState('bodywork',['bodyworks']),
	...mapState(['regions'])
	},
	methods: {
	...mapActions(['getRegions'])
	},
	components: {
    SocialChat
  }
}
</script>
<style lang="scss">
	@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@300;400;500;600&display=swap');
	@import "@/assets/scss/_variables";

	.footer{
		font-family: 'MuseoModerno', cursive;
		background-color: #fff;
		padding: 20px 10px;
		text-align: left;
		width: 100%;
		position: relative;
		bottom: 0;
		overflow: hidden;
		@media (max-width: 576px) {
			margin-bottom: 65px;
		}
		&-img{
			max-width: 100px;
			margin-bottom: 10px;
		}
		&-copyright{
			font-weight: 300;
			font-size: .75rem;
			color: #90a4ae;
		}
		h5{
			color: #607D8B;
			font-size: 1.1rem;
			padding: 0 10px;
		}
		ul{
			margin: 0;
			padding: 5px 10px;
			list-style: none;
			li{
				padding: .08rem 0;
				a{
					font-size: .875rem;
					font-weight: 400;
					color: #90a4ae;
					&:hover{
						color: $primary;
						text-decoration: none;
					}
				}
			}
		}
	}
</style>